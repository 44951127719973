<template>
  <section class="py-10 lg:py-20 bg-gray-50">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto">
        <div class="mb-10">
          <a class="font-bold leading-none" href="#">
            <img class="h-20 mx-auto" :src="require('../assets/cio-logo.png')" alt="">
          </a>
        </div>
        <div class="mb-6 lg:mb-10 p-6 lg:p-12 bg-white shadow rounded text-center">
          <p v-if="this.$route.query.fromForgotPassword == 'true'" class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50">Wir haben Ihnen einen Link zum Zurücksetzen Ihres Passworts per E-Mail zugesandt</p>
          <p v-if="this.$route.query.fromResetPassword == 'true'" class="mb-4 w-full py-2 bg-sky-600 rounded text-sm font-bold text-gray-50">Sie können sich jetzt mit Ihrem neuen Passwort anmelden</p>
          <div class="mb-6">
            <h3 class="text-2xl font-bold">Anmelden</h3>
          </div>
          <form onsubmit="return false" @submit="login()">
            <div class="mb-3 flex p-4 bg-gray-50 rounded">
              <input class="w-full text-xs bg-gray-50 outline-none" type="text" placeholder="Benutzername (z.B. Clubnummer)" v-model="username">
              <svg class="h-6 w-6 ml-4 my-auto text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
            </div>
            <div class="mb-6 flex p-4 bg-gray-50 rounded">
              <input class="w-full text-xs bg-gray-50 outline-none" :type="passwordFieldType" placeholder="Passwort" v-model="password">
              <button type="button" @mousedown="passwordFieldType = 'text'" @mouseup="passwordFieldType = 'password'">
                <svg class="h-6 w-6 ml-4 my-auto text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                </svg>
              </button>
            </div>
            <div class="text-center">
              <button class="mb-4 w-full py-4 bg-sky-600 hover:bg-sky-700 rounded text-sm font-bold text-gray-50" @click="login()">Einloggen</button>
              <p v-if="showWrongUsernameAndPassword" class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50">Falscher Benutzername oder falsches Passwort</p>
              <a v-if="false" @click="goToForgotPasswordPage()" class="text-xs text-sky-600 hover:underline">Passwort vergessen?</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
      data () {
    return {
      username: null,
      password: null,
      response: null,
      showWrongUsernameAndPassword: false,
      passwordFieldType: 'password',
    }
  },
    methods: {
    showPassword() {
      this.passwordFieldType = 'text'
    },
    login() {

      axios.post(`${process.env.VUE_APP_BACKEND_URL}/users/login`, {
        username: this.username,
        password: this.password,
      }).then(res => {
        console.log(res)
        console.log('Success')
      this.showWrongUsernameAndPassword = false
      this.$store.commit('setToken', res.data.token)
      this.$router.push('/applications')
      }).catch(err => {
        console.log(err)
        if (err.response.status === 401) console.log("Username oder Passwort Falsch")
        this.showWrongUsernameAndPassword = true
      })
    
    },
    goToForgotPasswordPage() {
      this.$router.push({ name: 'ForgotPassword' })
    }
    },
}
</script>
